import React from 'react';
import PropTypes from 'prop-types';
import Image from 'core/image';
import MainContainer from '../main-container';

import {
  ContainerPageHeader,
  PageHeaderTitle,
  PageHeaderDescription,
  ImgContainer,
  PageHeaderReadingTime,
  ContainerTitle,
} from './styles';

const ExpertPageHeader = ({ title, subtitle, readingTime, backgroundImage }) => {
  return (
    <ContainerPageHeader>
      {backgroundImage && (
        <ImgContainer>
          <Image
            alt={`${title} image`}
            fromContentful
            contentful_id={backgroundImage}
            fluid
            width="100%"
            height="100%"
          />
        </ImgContainer>
      )}
      <MainContainer>
        <ContainerTitle>
          <PageHeaderTitle>{title}</PageHeaderTitle>
          {subtitle && <PageHeaderDescription>{subtitle}</PageHeaderDescription>}
          {readingTime && (
            <PageHeaderReadingTime>{`${readingTime} min read`}</PageHeaderReadingTime>
          )}
        </ContainerTitle>
      </MainContainer>
    </ContainerPageHeader>
  );
};

ExpertPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  readingTime: PropTypes.number,
};

ExpertPageHeader.defaultProps = {};

export default ExpertPageHeader;
