import styled from 'styled-components';

export const Content = styled.li`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  flex-direction: row;
  justify-content: space-between;

  button {
    padding: 0;
  }

  &:hover {
    a {
      color: ${({ theme }) => theme.newTheme.color.primary.main};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    &:hover {
      a {
        color: ${({ theme }) => theme.newTheme.color.white};
      }
    }

`;

export const HeaderTitle = styled.a`
  margin: 0;

  color: ${({ theme }) => theme.newTheme.color.grey.main};
  font-size: ${({ theme }) => theme.newTheme.font.text2.size};
  line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};

  transition: ${({ theme }) => theme.transition};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
    line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  }
`;

export const Container = styled.div`
  height: 100%;
  position: relative;
`;

export const Body = styled.div`
  width: 100%;
  display: ${({ show }) => (show ? '' : 'none')};
  transition: ${({ theme }) => theme.transition};
`;

export const BodyDesc = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.newTheme.spacing(10)};

  animation: ${({ fade }) => (fade ? 'fadeInDown .2s linear' : 'fadeInUp .2s linear')};

  transition: ${({ theme }) => theme.transition};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(6)};

  a {
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    transition: ${({ theme }) => theme.transition};

    &:hover {
      color: ${({ theme }) => theme.newTheme.color.primary.main};
    }
  }

  li {
    margin-top: ${({ theme }) => theme.newTheme.spacing(4)};
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-40px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(-40px);
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    ${({ theme }) => `padding: ${theme.spacing.small} 0`};
    font-size: ${({ theme }) => theme.fonts.sizes.small};
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(0)};

    li {
      margin-bottom: ${({ theme }) => theme.newTheme.spacing(4)};
    }

    a {
      color: ${({ theme }) => theme.newTheme.color.white};
      line-height: ${({ theme }) => theme.spacing.large};
      padding-left: ${({ theme }) => theme.newTheme.spacing(5)};

      &:hover {
        color: ${({ theme }) => theme.newTheme.color.white};
      }
    }
  }
`;
