import styled from 'styled-components';

export const ContainerPageHeader = styled.div`
  padding: ${({ theme }) => `${theme.newTheme.spacing(40)} 0`};
  position: relative;
  width: 100%;
  z-index: 9;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    padding: ${({ theme }) => `${theme.newTheme.spacing(10)} 0 ${theme.newTheme.spacing(20)} 0`};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding: ${({ theme }) => `${theme.newTheme.spacing(10)} 0 ${theme.newTheme.spacing(10)} 0`};
  }
`;

export const ImgContainer = styled.div`
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    width: 100%;
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
      height: 100%;
    }
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      80.88deg,
      #3f3f3f 1.15%,
      rgba(63, 63, 63, 0.9375) 63.81%,
      rgba(63, 63, 63, 0) 137.9%
    );
    opacity: 0.6;
  }

  img {
    top: -35% !important;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.desktop}) {
    img {
      height: 120% !important;
    }
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    img {
      top: 0px !important;
      height: 100% !important;
    }
  }
`;

export const ContainerTitle = styled.div`
  max-width: 1156px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageHeaderTitle = styled.h1`
  font-size: ${({ theme }) => theme.newTheme.font.title1.size};
  line-height: ${({ theme }) => theme.newTheme.font.title1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  margin: ${({ theme }) => `${theme.newTheme.spacing(10)} 0 0 0`};
  color: ${({ theme }) => theme.newTheme.color.white};
  text-align: center;
  hyphens: auto;

  u {
    text-decoration: none;
    display: block;
  }

  b {
    background: ${({ color }) => color};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    margin: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  }
`;

export const PageHeaderDescription = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  width: 100%;
  color: ${({ theme }) => theme.newTheme.color.white};
  text-align: center;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const PageHeaderReadingTime = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.white};
  width: 100%;
  text-align: center;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    margin-top: 0;
    line-height: ${({ theme }) => theme.lineHeight.secondary};
  }
`;
