import styled from 'styled-components';

export const FaqsTitle = styled.h2`
  font-size: ${({ theme }) => theme.newTheme.font.title4.size};
  line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  margin: ${({ theme }) => theme.newTheme.spacing(10)} 0;
  color: ${({ theme }) => theme.newTheme.color.black};

  width: 80%;

  u {
    text-decoration: none;
    display: block;
  }

  b {
    background: ${({ color }) => color};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    margin: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    font-size: ${({ theme }) => theme.newTheme.font.title5.size};
    line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
  }
`;

export default FaqsTitle;
