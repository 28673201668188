import styled, { createGlobalStyle } from 'styled-components';
import PageSection from 'atomic-design/atoms/page-section';

export const GlobalExpertGuideStyle = createGlobalStyle`
  body, html {
    overflow: initial;
    scroll-behavior: smooth;

    h2 {
      scroll-margin-top: 100px;
    }
    h3 {
      scroll-margin-top: 100px;
    }
    h4 {
      scroll-margin-top: 100px;
    }
    h5 {
      scroll-margin-top: 80px;
    }
    h6 {
      scroll-margin-top: 80px;
    }
    p {
      scroll-margin-top: 80px;
    }

    h2:first-child {
      padding-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
    }

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      h2 {
        scroll-margin-top: 100px;
      }
      h3 {
        scroll-margin-top: 100px;
      }
      h4 {
        scroll-margin-top: 130px;
      }
    }
  }
`;

export const ExpertGuidePageSection = styled(PageSection)`
  overflow: initial;
`;

export const PillarContent = styled.div`
  display: flex;
  alignItems: "flex-start"
  flex-direction: row;
  align-items: flex-start;
 
  width: 100%;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    padding: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    flex-direction: column;
    padding: 0;
  }
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 36%;
  position: sticky;
  top: 100px;

  margin-top: ${({ theme }) => theme.newTheme.spacing(6)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 40%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin-top: 0;
    width: 100%;
    top: 53px;
    z-index: 11;
  }
`;

export const RichTextContainer = styled.div`
  margin-top: ${({ theme }) => `${theme.newTheme.spacing(6)}`};
  display: flex;
  flex-direction: column;
  flex: 1;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    padding: ${({ theme }) => `${theme.newTheme.spacing(5)} 27px`};
  }
`;

export const Diagonal = styled.div`
  position: absolute;
  z-index: 0;
  right: 0;
  border-top: ${({ theme }) => `${theme.newTheme.spacing(20)} solid white`};
  border-left: 100vw solid transparent;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    border-top: ${({ theme }) => `${theme.newTheme.spacing(10)} solid white`};
  }
`;

export const NavigationTitle = styled.h3`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.black};
  margin: ${({ theme }) => `0 0 ${theme.newTheme.spacing(20)} 0`};
  padding: ${({ theme }) => `${theme.newTheme.spacing(20)} 0 0 0`};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    display: none;
  }
`;
