import styled from 'styled-components';

export const Content = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;

  gap: ${({ theme }) => theme.newTheme.spacing(4)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    gap: ${({ theme }) => theme.newTheme.spacing(4)};
  }
`;
