import React, { useRef } from 'react';
import { parseFaqsList } from 'common/transformers';
import MetaTags from 'core/meta-tags/index';
import { graphql } from 'gatsby';
import Layout from 'core/layout';
import ExpertPageHeader from 'atomic-design/atoms/expert-page-header';
import theme from 'styles/theme';
import useReadingTime from 'use-reading-time';
import useIsMobile from 'hooks/useIsMobile';
import FaqsTag from '../components/core/faqs-tags';
import FaqSection from 'atomic-design/organisms/faq-section';

import {
  GlobalExpertGuideStyle,
  PillarContent,
  SidebarContainer,
  RichTextContainer,
  ExpertGuidePageSection,
  Diagonal,
  NavigationTitle,
} from 'styles/pages/expert-guides-styles';

import RichTextWrapper from 'atomic-design/organisms/rich-text-wrapper';
import richTextOptions from 'common/rich-text-options';
import GuideSidebar from 'components/atomic-design/organisms/guide-sidebar';
import NavigationCards from 'atomic-design/molecules/navigation-cards';
import PageSection from '../components/atomic-design/atoms/page-section';

export const query = graphql`
  query ($slug: String!) {
    contentfulGuide(slug: { eq: $slug }) {
      title
      subtitle
      slug
      publishedDate
      hero {
        contentful_id
        title
        file {
          url
        }
      }
      tableOfContents {
        json
      }
      body {
        json
      }
      faQs {
        title
        items {
          answer {
            json
          }
          question
        }
      }
    }
  }
`;

const ExpertGuides = ({ pageContext, data, location }) => {
  const { previous, next } = pageContext;
  const isMobile = useIsMobile('mobile');
  const guidePage = data.contentfulGuide;
  const guideContent = useRef();
  const { readingTime } = useReadingTime(guideContent);
  const mockedCategory = { name: 'Guide', slug: 'guide' };

  const faqList = guidePage.faQs && parseFaqsList(guidePage.faQs.items);
  return (
    <Layout path={location.pathname} withReadingProgress>
      <GlobalExpertGuideStyle />
      <MetaTags
        title={guidePage.title}
        description={guidePage.subtitle}
        charSet="utf-8"
        image={guidePage.hero.file.url}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />
      {guidePage.faQs && <FaqsTag list={faqList} />}
      <ExpertGuidePageSection
        margin={`${theme.newTheme.spacing(13.5)} 0 0 0`}
        mobileMargin="0 0 0 0"
        containerSize="fluid"
      >
        <ExpertPageHeader
          title={guidePage.title}
          subtitle={guidePage.subtitle}
          readingTime={readingTime}
          backgroundImage={guidePage.hero.contentful_id}
        />
      </ExpertGuidePageSection>
      <ExpertGuidePageSection
        padding={`0 0 ${theme.newTheme.spacing(20)} 0`}
        mobilePadding="0 0 0 0"
        mobileMargin="0 0 0 0"
        containerSize={isMobile ? 'fluid' : 'sm'}
      >
        <PillarContent>
          <SidebarContainer>
            <GuideSidebar content={guidePage.tableOfContents.json} />
          </SidebarContainer>
          <RichTextContainer ref={guideContent}>
            <RichTextWrapper
              color={theme.newTheme.color.primary.main}
              json={{
                data: guidePage.body.json.data,
                content: guidePage.body.json.content,
                nodeType: guidePage.body.json.nodeType,
              }}
              options={richTextOptions}
            />
            {guidePage.faQs && <FaqSection faqList={faqList} title={guidePage.faQs.title} />}
          </RichTextContainer>
        </PillarContent>
      </ExpertGuidePageSection>
      <PageSection
        background={`${theme.newTheme.color.primary.light}20`}
        margin={`${theme.newTheme.spacing(25)} 0 0 0`}
        padding={`0 0 ${theme.newTheme.spacing(25)} 0`}
        mobilePadding={`0 0 ${theme.newTheme.spacing(1)} 0`}
      >
        <Diagonal />
        <NavigationTitle>More guides to read</NavigationTitle>
        <NavigationCards
          previous={{ ...previous.node, category: mockedCategory }}
          previousTo={`/expert-guides/${previous.node.slug}`}
          next={next ? { ...next.node, category: mockedCategory } : undefined}
          nextTo={`/expert-guides/${next.node.slug}`}
          from="Guide"
          withArrow
        />
      </PageSection>
    </Layout>
  );
};

export default ExpertGuides;
