import React, { useState, useEffect } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import ChevronButtonIcon from 'atomic-design/atoms/chevronButtonIcon';
import SidebarMenu from './sidebar-menu';
import { SidebarContainer, Title, BodyDesc, TitleContainer, ListDivider } from './styles';

const guideSidebarMapper = ({ content }) => {
  const buildContentLinks = json =>
    json.content.reduce((acc, x) => {
      if (x.nodeType === 'paragraph') {
        if (x.content.length === 1) {
          return [...acc, { title: x.content[0].value }];
        }

        const node = x.content[1];
        const element = {
          title: node.content[0].value,
          link: node.data.uri,
        };

        return [...acc, element];
      }
      return [...acc, buildContentLinks(x)];
    }, []);

  const recursiveSidebarMenu = content => {
    return content.reduce((acc, x) => {
      if (x.length > 1) {
        if (x[1].length > 1) {
          return [...acc, { parent: x[0], children: recursiveSidebarMenu(x[1]) }];
        }
        return [...acc, { parent: x[0], children: x[1] }];
      }
      return [...acc, { parent: x[0] }];
    }, []);
  };

  const data = buildContentLinks(content[0]);
  return recursiveSidebarMenu(data);
};

const GuideSidebar = ({ content }) => {
  const MappedContent = guideSidebarMapper(content);

  const isMobile = useIsMobile('mobile');
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  useEffect(() => {
    if (!isMobile) {
      setShow(false);
    }
  }, [isMobile]);

  return (
    <SidebarContainer open={show}>
      {isMobile ? (
        <>
          <TitleContainer onClick={handleClick}>
            <Title>What’s included in this guide</Title>
            <ChevronButtonIcon openClose={!show} />
          </TitleContainer>
          <ListDivider show={show} />
        </>
      ) : (
        <Title>What’s included in this guide</Title>
      )}

      <BodyDesc show={isMobile ? show : true} fade>
        <SidebarMenu content={MappedContent} setShowMenu={setShow} />
      </BodyDesc>
    </SidebarContainer>
  );
};

export default GuideSidebar;
