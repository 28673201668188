import styled from 'styled-components';

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  position: ${({ open }) => (open ? 'fixed' : '')};
  top: ${({ open }) => (open ? '53px' : '')};
  left: ${({ open }) => (open ? '0' : '')};
  width: ${({ open }) => (open ? '100%' : '')};
  
  padding: ${({ theme }) => theme.newTheme.spacing(5)};
  margin: ${({ theme }) => `0 ${theme.newTheme.spacing(9)} 0 0`};

  width: 100%
  height: 100%;

  background: #ffffff;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.newTheme.color.primary.main};


  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    border: none;
    border-radius: 0;
    background: ${({ theme }) => theme.newTheme.color.primary.main};

    margin: 0;
    padding: ${({ theme }) => `0 ${theme.newTheme.spacing(7)}`};
    
    
    p {
      color: white;
    }
    a {
      color: white;
    }
  }
  
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  color: ${({ theme }) => theme.newTheme.color.primary.main};
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(5)};
  margin-top: 0;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    margin-top: ${({ theme }) => theme.newTheme.spacing(5)};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  button {
    padding: 0;
  }
`;

export const BodyDesc = styled.div`
  display: ${({ show }) => (show ? '' : 'none')};
  width: 100%;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(5)};

  animation: ${({ fade }) => (fade ? 'fadeInDown .2s linear' : 'fadeInUp .2s linear')};

  transition: ${({ theme }) => theme.transition};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    height: 100vh;
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-40px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(-40px);
    }
  }
`;

export const ListDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.newTheme.color.white};
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(5)};
`;
