import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';
import ReactDOMServer from 'react-dom/server';

const FaqsTag = props => {
  const { list } = props;

  const mapFaqList = faqList =>
    faqList.map(item => ({
      '@type': 'Question',
      name: item.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: ReactDOMServer.renderToString(documentToReactComponents(item.description.json)),
      },
    }));

  const faqScript = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: mapFaqList(list),
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(faqScript)}</script>
    </Helmet>
  );
};

FaqsTag.propTypes = {
  list: PropTypes.array.isRequired,
};

export default FaqsTag;
