import React, { useState } from 'react';
import { Content } from './styles';
import SidebarItem from './sidebar-item';

const SidebarMenu = ({ content, setShowMenu }) => {
  const [openKey, setOpenKey] = useState();

  const handleToggle = key => {
    setOpenKey(openKey !== key ? key : '');
  };

  return (
    <Content>
      {content.map(guideItem => {
        return (
          <SidebarItem
            key={guideItem.parent.title}
            name={guideItem.parent.title}
            toggle={handleToggle}
            open={openKey === guideItem.parent.title}
            title={guideItem.parent}
            submenu={guideItem.children}
            setShowMenu={setShowMenu}
          />
        );
      })}
    </Content>
  );
};
export default SidebarMenu;
