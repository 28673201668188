import React from 'react';
import PropTypes from 'prop-types';
import { ChevronArrow } from 'ui/svg';

import { IconContainer } from './styles';

const ChevronButtonIcon = ({ openClose, onClick, size = 25, color = '#ffffff' }) => {
  return (
    <IconContainer openClose={openClose} onClick={onClick}>
      <ChevronArrow size={size} color={color} />
    </IconContainer>
  );
};

ChevronButtonIcon.propTypes = {
  openClose: PropTypes.bool.isRequired,
};

export default ChevronButtonIcon;
