import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'atomic-design/molecules/accordion';
import FaqsTitle from './styles';

const FaqSection = ({ faqList, title }) => {
  return (
    <div id="faqs">
      <FaqsTitle>{title}</FaqsTitle>
      <Accordion size="small" list={faqList} />
    </div>
  );
};

FaqSection.propTypes = {
  faqList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default FaqSection;
