import styled from 'styled-components';

export const IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;

  ${({ openClose }) => (openClose ? 'transform: rotate(-90deg)' : 'transform: rotate(0deg)')};
  transition: ${({ theme }) => theme.transition};
`;
