import React, { useState } from 'react';
import ChevronButtonIcon from 'atomic-design/atoms/chevronButtonIcon';
import { Content, Header, Body, HeaderTitle, BodyDesc, Container } from './styles';
import useIsMobile from 'hooks/useIsMobile';
import theme from 'styles/theme';

const SidebarItem = ({ name, toggle, open, title, submenu, setShowMenu }) => {
  const isMobile = useIsMobile('mobile');
  const [hover, setHover] = useState(false);

  const colorChevron = () => {
    if (isMobile) {
      return '#ffffff';
    }
    return hover ? theme.newTheme.color.primary.main : theme.newTheme.color.grey.main;
  };

  const recursiveMapperOfItems = submenuItem => {
    if (Array.isArray(submenuItem)) {
      return recursiveMapperOfItems({ parent: submenuItem[0], children: submenuItem[1] });
    }

    return (
      <>
        <li key={submenuItem?.parent?.title}>
          <a onClick={() => setShowMenu(false)} href={submenuItem?.parent?.link || '#'}>
            {submenuItem?.parent?.title || 'Untitled'}
          </a>
        </li>
        {submenuItem?.children?.map(childrenItem => {
          if (Array.isArray(childrenItem)) {
            return recursiveMapperOfItems(childrenItem);
          }

          return recursiveMapperOfItems({
            parent: childrenItem.parent,
            children: childrenItem.children,
          });
        })}
      </>
    );
  };

  return (
    <Content key={name}>
      <Header
        onClick={() => toggle(name)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <HeaderTitle onClick={() => setShowMenu(false)} href={title.link && title.link}>
          {title && title.title}
        </HeaderTitle>

        {submenu && <ChevronButtonIcon openClose={!open} color={colorChevron()} />}
      </Header>

      {submenu && (
        <Body show={open}>
          <Container>
            <BodyDesc fade={open}>
              {submenu &&
                submenu.map(submenuItem => {
                  return recursiveMapperOfItems(submenuItem);
                })}
            </BodyDesc>
          </Container>
        </Body>
      )}
    </Content>
  );
};
export default SidebarItem;
